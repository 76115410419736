.c-notation__details__list {
  margin: 0;
  padding: 5px 0;
  text-align: left;
}

.c-notation__details__item {
  font-size: 1.3rem;

  .icon {
    display: inline-block;
    margin-right: 5px;
  }
}

// Declarations
// =============================================================================
.c-notation {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  margin: 15px 0 0;

  &__box {
    position: relative;
  }

  &__letter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 45px;
    color: white;
    line-height: 1;
  }

  &__item {
    width: 75px;
    height: 75px;
    background: #f3f3f3;
    position: relative;
  }

  &__generate {
    text-align: right;
    margin-top: 15px;
  }

  &__details {
    font-size: 1.2rem;
  }
}
$notation: -1;

@while $notation <= 100 {
  $notation: $notation + 1;

  [data-notation='#{$notation}'] {
    @if $notation <= 20 {
      background-color: $progress-0;
    }

    @else if $notation <= 40 {
      background-color: $progress-20;
    }

    @else if $notation <= 60 {
      background-color: $progress-40;
    }

    @else if $notation <= 80 {
      background-color: $progress-60;
    }

    @else if $notation < 100 {
      background-color: $progress-80;
    }

    @else if $notation == 100 {
      background-color: $progress-100;
    }

    @else {
      background-color: $progress-0;
    }
  }
}
