.sub-heading {
  margin: 5px 15px;
  font-family: Montserrat, sans-serif;
  color: hsla(0, 0%, 100%, .6);
  font-size: 1.3rem;
  line-height: 1.5;

  @include mq('handheld-and-up') {
    font-size: 1.5rem;
    margin: 15px;
  }
}

.s-header {
  grid-row: 1;
  grid-column-start: 2;
  padding: 15px 0;
  background-color: #415257;
  background-image: linear-gradient(45deg, #415257, #292e49);
  color: map-get($colors, white);
  font-size: 1rem;
  text-align: center;
  position: relative;

  @include mq('handheld-and-up') {
    padding-top: 25px;
    padding-bottom: 60px;
  }

  @include mq('print') {
    padding: 0;
  }

  &__banner {
    @include mq('handheld-and-up') {
      width: 795px;
      margin: 0 auto;
    }
  }

  &__media__list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    padding: 0;
  }

  &__media__item {
    margin: 0 5px;
  }

  &__checklist {
    display: grid;
    grid-template-columns: auto;

    @include mq('print') {
      margin: 0;
    }

    @include mq('handheld-and-up') {
      grid-template-rows: auto auto;
      grid-template-columns: repeat(5, 1fr);
      padding: 10px;
      border: 1px solid lightgray;
    }

    &__title {
      display: none;
    }

    &__el {
      display: grid;

      @include mq('handheld-and-up') {
        padding: 0 20px 0 0;
      }
    }
    // Form
    &__el:nth-child(2) {
      grid-column: 6;
      grid-row: 1 / 2;

      @include mq('print') {
        grid-column: 1 / 4;
        grid-row: 1;
      }

      @include mq('handheld') {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
      }

      @include mq('lap-and-up') {
        grid-column: 1 / 2;
        grid-row: 1 / 1;
      }
    }
    // Nav
    &__el:nth-child(3) {
      grid-column: 6;
      grid-row: 3 / 4;

      @include mq('print') {
        display: none;
      }

      @include mq('handheld') {
        grid-column: 1 / 6;
        grid-row: 2 / 2;
      }

      @include mq('lap-and-up') {
        grid-column: 2 / 4;
        grid-row: 1 / 1;
      }
    }
    // Notation
    &__el:nth-child(4) {
      grid-column: 6;
      grid-row: 2 / 3;

      @include mq('print') {
        grid-column: 5 / 6;
        grid-row: 1;
      }

      @include mq('handheld') {
        grid-column: 4 / 6;
        grid-row: 1 / 2;
      }

      @include mq('lap-and-up') {
        grid-column: 4 / 6;
        grid-row: 1 / 1;
        padding-right: 0;
      }
    }
  }

  &__lang__list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    justify-content: center;
    margin-top: 20px;
  }

  &__lang__item {
    margin: 0 5px;
  }
}
