$body-background-color: #ededed !default;
$body-size: 62.5% !default;
$body-rendering: optimizeLegibility !default;
$body-family: $family-sans-serif !default;
$body-color: $text !default;
$body-weight: $weight-normal !default;
$body-line-height: 1.5 !default;

// $hr-background-color: $border !default;
$hr-height: 1px !default;
$hr-margin: 1.5rem 0 !default;
$strong-color: $text-strong !default;
$strong-weight: $weight-bold !default;

// Set box-sizing globally to handle padding and border widths
*,
*::after,
*::before {
  box-sizing: inherit;
}

// The base font-size is set at 62.5% for having the convenience
// of sizing rems in a way that is similar to using px: 1.6rem = 16px
html {
  background-color: $body-background-color;
  min-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  font-size: $body-size;
  text-rendering: $body-rendering;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

// Default body styles
body {
  color: $body-color;
  font-size: 1rem;
  font-weight: $body-weight;
  line-height: $body-line-height;
  font-family: $family-sans-serif;
  margin: 0;
}
