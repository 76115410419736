.c-tools {
  display: flex;

  @include mq('print') {
    display: none;
  }

  .is-active {
    border-color: #415257;
  }

  .c-button__group:first-child {
    margin-left: 0;
  }
}
