fieldset {
  border: 0;
  margin: 0;
}

label:hover {
  cursor: pointer;
}

.s-form {
  margin: 10px 0 20px;

  @include mq('handheld-and-up') {
    margin-bottom: 20px;
  }
}

.form {
  input {
    font-size: 1.5rem;
    padding: 5px;
    display: block;
    border: 0;
    width: 100%;
    border-radius: 0;
    border-bottom: 1px solid #757575;

    @include mq('handheld-and-up') {
      width: 300px;
    }

    &:focus {
      outline: none;
    }

    &:focus ~ label,
    &:valid ~ label {
      top: -15px;
      font-size: 12px;
      color: map-get($colors, $primary);
    }
  }

  label {
    color: #999999;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 0;
    transition: .2s ease all;
  }
}

.form-group {
  position: relative;
  margin: 20px 0;

  &:last-of-type {
    margin: 20px 0 0;
  }
}

.label__title {
  font-weight: bold;
  display: inline;
}

label,
.nav li a,
.nav li input {
  transition: background-color .08s ease-in-out;
}
