@if $use-progress == true {

  $i: 0;

  #{$namespace-progress} {
    position: relative;
    display: flex;
    padding: 10px 0;

    @include mq('handheld-and-up') {
      padding: 0;
      display: block;
    }

    &__counter {
      text-align: center;
      margin-left: 20px;

      @include mq('handheld-and-up') {
        margin: 0;
      }
    }

    &__label {
      font-size: 1.3rem;
      font-weight: 600;

      @include mq('handheld-and-up') {
        position: absolute;
        top: 28%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__text {
      font-size: 1.3rem;

      @include mq('handheld-and-up') {
        font-size: 1.1rem;
        padding: 0 5px;
      }
    }
  }

  .c-progress__bar {
    background-color: map-get($shades, white-ter);
    border: 0;
    height: 19px;
    border-radius: 3px;
    width: 120px;

    @include mq('handheld-and-up') {
      height: 20px;
      margin: 0 20px;
    }

    .c-progress--big & {
      height: 30px;
      width: 150px;
    }

    &::-webkit-progress-value {
      border-radius: 2px;
    }

    @while $i < 100 {
      $i: $i + 1;
      $hue: round((65 / 100) * $i);

      &[value='#{$i}'] {
        &::-webkit-progress-value {
          background-color: adjust-hue($progress-20, $hue);
        }
      }
    }

    &::-webkit-progress-bar {
      background-color: map-get($shades, white-ter);
      transition: all 300ms ease-in 0s;
    }
  }
}
