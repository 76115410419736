.icon {
  width: 18px;
  height: 18px;
  transition: all 200ms ease-in 0s;
  fill: map-get($colors, grey-light);
  display: inline-block;
  text-align: center;
  speak: none;
  backface-visibility: hidden;
  opacity: 1;

  @include mq('handheld-and-up') {
    width: 15px;
    height: 15px;
  }
}

button,
.button {
  .icon {
    margin: 0 .7rem 0 -.2rem;
  }
}

.icon--small {
  width: 7px;
  height: 7px;
}

.icon-checkbox {
  .path-check {
    display: none;
  }
}

.icon-uncheck {
  .path-check {
    display: none;
  }
}

.icon-checkbox,
.icon-checked {
  align-self: flex-start;
  width: 11px;
  height: 11px;
  fill: $primary;
  opacity: 1;

  @include mq('handheld-and-up') {
    width: 15px;
    height: 15px;
  }

  [data-item-check='false'] & {
    display: block;
  }

  [data-item-check='true'] & {
    display: none;
  }
}

.icon-checked {
  fill: map-get($colors, black);
  opacity: .1;

  [data-item-check='false'] & {
    display: none;
  }

  [data-item-check='true'] & {
    display: block;
  }
}

.icon-arrow {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  fill: map-get($colors, black);
  opacity: .2;
}

.icon-rotate {
  transform: rotate(180deg);
  transition: all 300ms ease-in 0s;
}

.icon-priority {
  &--high {
    fill: #f85f5f;
    align-self: flex-start;
    display: block;
    width: 7px;
    height: 7px;

    @include mq(lap-and-up) {
      margin-top: 0;
      width: 7px;
      height: 7px;
    }
  }

  &--medium {
    fill: #f2c741;
    align-self: flex-start;
    display: block;
    width: 7px;
    height: 7px;

    @include mq(lap-and-up) {
      margin-top: 0;
      width: 7px;
      height: 7px;
    }
  }

  &--low {
    fill: #62c547;
    align-self: flex-start;
    display: block;
    width: 7px;
    height: 7px;

    @include mq(lap-and-up) {
      margin-top: 0;
      width: 7px;
      height: 7px;
    }
  }
}

.btn--danger {
  border-color: #df5941;
  color: #df5941;

  .icon {
    fill: #df5941;
  }
}

@keyframes bounce {
  0% {
    transform: scaleX(1);
  }

  10% {
    transform: scaleX(1);
  }

  20% {
    transform: scaleX(.8);
  }

  35% {
    transform: scaleX(.8);
  }

  45% {
    transform: scaleX(1);
  }

  60% {
    transform: scaleX(1);
  }

  75% {
    transform: scaleX(.8);
  }

  85% {
    transform: scaleX(.8);
  }

  100% {
    transform: scaleX(1);
  }
}

.code-icons {

  // height: 100%;
  margin: 0 auto;
  position: relative;
  width: 25px;

  &::after,
  &::before {
    box-sizing: content-box;
    content: '';
    height: 10px;
    position: absolute;
    top: 9px;
    transform: rotate(45deg);
    width: 10px;
  }

  &::before {
    border-bottom: 3px solid #cccccc;
    border-left: 3px solid #cccccc;
    left: 0;
  }

  &::after {
    border-right: 3px solid #cccccc;
    border-top: 3px solid #cccccc;
    right: 0;
  }
}

.icon-eye {
  path:nth-child(2),
  path:nth-child(3) {
    display: none;
  }

  &-hide {
    fill: #415257;

    path:nth-child(2),
    path:nth-child(3) {
      display: block;
    }
  }
}



.st0 {
  fill: map-get($colors, white);
}
