.s-footer {
  display: grid;
  text-align: center;
  padding: 20px;

  @include mq(lap-and-up) {
    padding: 50px 0;
  }

  @include mq('print') {
    padding: 10px;
  }

  &__badges {
    @include mq('print') {
      display: none;
    }
  }

  &__grateful {
    @include mq('print') {
      display: none;
    }
  }

  &__made {
    font-size: 1.4rem;

    @include mq('print') {
      font-size: 7pt;
    }
  }
}
