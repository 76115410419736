.media {
  align-items: flex-start;
  display: flex;
  text-align: left;
}

.img-logo {
  width: 85px;
  height: 85px;

  @include mq('handheld-and-up') {
    width: 100px;
    height: 100px;
  }
}

// frameborder="0"
// allowtransparency="true"
// scrolling="no"
iframe {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
}
