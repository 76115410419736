.c-sponsor {
  width: 100%;
}

.cs {
  &__wrapper {
    min-width: 320px;
    overflow: hidden;
  }
}
