@if $use-checklist == true {

  // Variables
  // =============================================================================
  $checklist-background-color: map-get($colors, white) !default;
  $checklist-box-shadow: null !default;

  // Declarations
  // =============================================================================
  #{$namespace-checklist} {
    & [data-item-visible='false'] {
      display: none;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__column {
      // padding: 8px 4px;
      padding: 0 0 10px;

      @include mq('handheld') {
        padding: 2px;
      }

      @include mq('handheld-and-up') {
        padding: 8px 4px;
      }

      @include mq('print') {
        padding: 0;
      }

      // Priority bullet
      &:nth-child(1) {
        padding-top: 12px;

        @include mq('handheld-and-up') {
          padding-top: 15px;
        }

        @include mq('print') {
          margin: 0 5px;
          padding-top: 5px;
        }
      }

      // Checkboxe
      &:nth-child(2) {
        padding-top: 11px;

        @include mq('handheld') {
          padding-top: 11px;
        }
      }
    }

    &__intro {
      font-size: 1.4rem;
    }

    &__body {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: stretch;
      flex: 1;

      label {
        font-weight: 400;
        line-height: 1;
        position: relative;
        display: block;

        @include mq('handheld-and-up') {
          font-size: 1.6rem;
          line-height: 1.4;
        }

        &::before {
          border-color: #bdc3c7;
        }
      }

      input {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 15px;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
    }

    .label__title,
    .label__description {
      font-size: 1.3rem;

      @include mq('handheld-and-up') {
        font-size: 1.5rem;
      }
    }

    &__item {
      opacity: 1;
      height: auto;
      transition-timing-function: ease-in;
      font-size: 24px;
      line-height: 31px;
      display: block;
      background-color: $checklist-background-color;
      box-shadow: $checklist-box-shadow;
      overflow: hidden;
      width: 100%;
      position: relative;

      @include mq('print') {
        padding: 5px 0;
      }

      &::before {
        content: '';
        position: absolute;
        border-top: 1px solid #e4ebf0;
        width: 100%;
      }

      &__inner {
        display: flex;
      }

      &:nth-child(even) {
        background: #f8f7f6;
      }

      &:hover {
        background-color: rgb(241, 240, 237);
      }
    }

    &__priority {
      order: 1;
      flex-basis: 1%;
    }

    &__checkbox {
      order: 2;
      flex-basis: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq('print') {
        flex-basis: 25px;
      }

      @include mq('handheld-and-up') {
        flex-basis: 40px;
      }
    }

    &__label {
      order: 3;
      flex: 1;
    }

    &__dropdown {
      order: 4;
      flex-basis: 35px;
      position: relative;
      background-color: #f1f0ed;

      @include mq('desk') {
        flex-basis: 3%;
      }

      &__button {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 15px;
        width: 100%;
        height: 100%;
        z-index: 999;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        display: flex;
      }

      [data-item-check='true'] & {
        cursor: not-allowed;
      }
    }
  }
}

#{$namespace-checklist} {
  &__introduction {
    font-size: 1.5rem;
    margin-top: 0;
  }

  &__details {
    padding: 10px 10px 0;

    @include mq('handheld-and-up') {
      padding: 15px 15px 0;
      width: 100%;
    }

    &[aria-expanded='false'] {
      overflow: hidden;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      transition-duration: .3s;
      transition-timing-function: cubic-bezier(0, 1, .5, 1);
      opacity: 0;
    }

    &[aria-expanded='true'] {
      transition-duration: .3s;
      transition-timing-function: ease-in;
      max-height: 1000px;
      overflow: hidden;
      opacity: 1;
    }

    [data-item-check='true'] & {
      opacity: .3;
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 0;
  }

  &__code {
    // TODO: fix the Github Iframe
    display: none;

    @include mq('desk') {
      display: block;
      border: 1px solid #cccccc;
      background-color: white;
    }

    @include mq('print') {
      display: block;
    }

    &__loader {
      padding: 10px 0;
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto;
    }
  }

  &__favicon {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    display: inline;

    &[data-src] {
      display: none;
    }

    .no-js & {
      display: none;
    }
  }

  .documentation__list {
    margin: 0;
    padding: 0 0 0 20px;
    list-style: circle;

    li {
      font-size: 1.2rem;
      line-height: 1.8;

      @include mq('handheld-and-up') {
        font-size: 1.5rem;
        line-height: 1.5;
      }
    }
  }
}

.c-checklist__documentation,
.c-checklist__tools {
  padding: 0 0 5px;
  margin: 0;
}


[data-item-check='true'] label {
  text-decoration: line-through;
  opacity: .3;
}
