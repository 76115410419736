.s-aside {
  position: fixed;
  transform: translateX(960px);
  text-align: left;
  margin-top: 55px;
  font-size: 1.5rem;
  top: 300px;

  ul {
    margin: 0;
    padding: 0;
  }
}

@if $use-aside == true {

  // Variables
  // =============================================================================
  $aside-height: 64px;
  $aside-background: #f8f7f6;
  $aside-border: #dddddd;
  $aside-collapse-breakpoint: 768px;
  $aside-item-font-size: 14px;
  $aside-item-border-width: 4px;
  $aside-item-color: #555555;
  $aside-item-active-color: #333333;
  $aside-item-border: transparent;
  $aside-item-active-border: #673ab7;

  // Declarations
  // =============================================================================
  #{$namespace-aside} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    flex-flow: wrap row;

    @include mq('print') {
      display: none;
    }

    @include mq(lap-and-up) {
      display: flex;
      flex-direction: row;
    }

    &__title {
      font-size: 1.2rem;
      font-weight: 700;

      @include mq('handheld-and-up') {
        font-size: 1.3rem;
      }
    }

    &__inner {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
    }

    &__list {
      flex-direction: row;
      width: 100%;
      margin: 0;
      padding: 10px;
      background-color: $aside-background;

      @include mq(lap-and-up) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__item {
      display: inline-block;
    }

    &__item a,
    &__item button {
      display: block;
      text-transform: uppercase;
      font-size: 1.3rem;
      line-height: 1;
      color: map-get($colors, black-bis);
      padding: 5px 10px;
      border: 2px solid transparent;
      background-color: transparent;

      &:hover {
        text-decoration: none;
        font-weight: $weight-normal;
        border: 2px solid #dbdbdb;
      }
    }
  }
}
