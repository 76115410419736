// Colors
$colors: (
  black:        hsl(0, 0%, 4%),
  black-bis:    hsl(0, 0%, 7%),
  black-ter:    hsl(0, 0%, 14%),
  grey-darker:  hsl(0, 0%, 21%),
  grey-dark:    #212529,
  grey:       hsl(0, 0%, 48%),
  grey-light:   hsl(0, 0%, 71%),
  grey-lighter: hsl(0, 0%, 86%),
  white-ter:    hsl(0, 0%, 96%),
  white-bis:    hsl(0, 0%, 98%),
  white:        hsl(0, 0%, 100%),
  orange:       #d75637,
  yellow:       hsl(48, 100%, 67%),
  green:        hsl(141, 71%, 48%),
  blue:         #415257,
  red:          red
);

// :root {
//   // each item in color map
//   @each $name, $color in $colors {
//     --color-#{$name}: $color;
//   }
// }

// Lists and maps
$colors: ('white': ($white, $black), 'black': ($black, $white), 'light': ($light, $light-invert), 'dark': ($dark, $dark-invert), 'primary': ($primary, $primary-invert), 'info': ($info, $info-invert), 'success': ($success, $success-invert), 'warning': ($warning, $warning-invert), 'danger': ($danger, $danger-invert)) !default;

$shades: ('black-bis': map-get($colors, black-bis), 'black-ter': map-get($colors, black-ter), 'grey-darker': map-get($colors, grey-darker), 'grey-dark': map-get($colors, grey-dark), 'grey': map-get($colors, grey), 'grey-light': map-get($colors, grey-light), 'grey-lighter': map-get($colors, grey-lighter), 'white-ter': map-get($colors, white-ter), 'white-bis': map-get($colors, white-bis)) !default;

$primary: map-get($colors, orange) !default;
$secondary: map-get($colors, blue) !default;

$info: map-get($colors, blue) !default;
$success: map-get($colors, green) !default;
$warning: map-get($colors, yellow) !default;
$danger: map-get($colors, red) !default;
$light: map-get($colors, white-ter) !default;
$dark: map-get($colors, grey-darker) !default;
$background: map-get($colors, white-ter) !default;
$border: map-get($colors, grey-lighter) !default;
$border-hover: map-get($colors, grey-light) !default;

$color-tint: 9 !default;
$color-tint-dark: $color-tint !default;
$color-tint-darker: $color-tint * 2 !default;
$color-tint-light: $color-tint !default;
$color-tint-lighter: $color-tint * 2 !default;


$progress-0: #6f2110;
$progress-20: #f63a0f;
$progress-40: #f27011;
$progress-60: #f2b01e;
$progress-80: #f2d31b;
$progress-100: #86e01e;
