.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  margin: 0;
  padding: 15px 0 0;
  font-weight: 700;
  font-size: 2.5rem;

  @include mq('handheld-and-up') {
    font-size: 3rem;
  }

  @include mq('print') {
    font-size: 18pt;
    padding: 0 0 5px;
  }
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.2rem;
  font-weight: 700;

  @include mq('handheld-and-up') {
    font-size: 1.3rem;
  }
}

h4,
.h4 {
  font-size: 1.2rem;
  font-weight: 700;

  @include mq('handheld-and-up') {
    font-size: 1.3rem;
  }
}
