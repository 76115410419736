@import 'm-breakpoints';

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ol,
ul {
  list-style: none;
}

.sr-only {
  display: none;
}
